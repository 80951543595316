<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/signupbg.png')">
			<div class="content">
				<h1 class="mb-5">Register</h1>
				<p class="text-lg">Use this form to activate your Quikstone Capital Solutions Portal account.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h6 class="font-semibold text-center">Please use the email address we have on file.</h6>
			</template>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<input type="hidden" name="userType" value="Agent" />
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [
							{
								type: 'email',
								message: 'Please check your email address for typos',
							},
							{
								required: true,
								message: 'Please enter your email address',
							},
						  ], 
						},
						]"
						placeholder="Email"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
							'password',
							{ rules: [
								{ required: true, min:8, message: 'Please input a password of 8 characters minimum' },
								{ validator: checkComplexity }
							] },
						]"
						type="password"
						placeholder="New Password"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password2',
						{ rules: [
								{ required: true, message: 'Please repeat your password' },
								{ validator: passwordConfirmationRule }
							] },
						]"
						type="password"
						placeholder="Repeat Password"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
							'termsandconditions',
							{
								valuePropName: 'checked',
								initialValue: true,
								rules: [
										{ required: true, message: 'Please accept terms and conditions' }
								]
							},
						]"
					>
						I agree to the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-alert v-if="errorState" :message="message" type="warning" show-icon />
				<a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						SIGN UP
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign In</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
	import User from '../models/user';

	export default ({
		data() {
			return {
				user: new User('', ''),
				errorState: false,
				message: ''
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			passwordConfirmationRule(rule, value, callback){
				if(value === this.form.getFieldValue('password')){
					callback();
					return;
				} else {
					callback('Passwords must match');
					return;
				}
			},
			checkComplexity(rule, value, callback) {
				const containsUppercase = /[A-Z]/.test(value)
				const containsLowercase = /[a-z]/.test(value)
				const containsNumber = /[0-9]/.test(value)
				const containsSpecial = /[#?!@$%^&*-]/.test(value)
				
				if( containsUppercase && containsLowercase && containsNumber && containsSpecial ){
					callback();
					return;
				} else {
					callback('Password must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character.');
					return;
				}
			},
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					this.user.email = values.email
					this.user.password = values.password
					this.user.userType = values.userType

					if(values.termsandconditions == false)
						alert("Please accept the terms and conditions.")

					if ( !err ) {
						this.$store.dispatch("auth/register", this.user).then(
							() => {
								this.$router.push("/userawaitingverification");
							},
							(error) => {
								this.loading = false;
								this.errorState = true;
								this.message =
									(error.response &&
									error.response.data &&
									error.response.data.message) ||
									error.message ||
									error.toString();
							}
						);
					}
				});
			},
		},
	})

</script>

<style lang="scss">
</style>