var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold text-center"},[_vm._v("Please use the email address we have on file.")])]},proxy:true}])},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('input',{attrs:{"type":"hidden","name":"userType","value":"Agent"}}),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'email',
					{ rules: [
						{
							type: 'email',
							message: 'Please check your email address for typos',
						},
						{
							required: true,
							message: 'Please enter your email address',
						} ], 
					} ]),expression:"[\n\t\t\t\t\t'email',\n\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\tmessage: 'Please check your email address for typos',\n\t\t\t\t\t\t},\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\tmessage: 'Please enter your email address',\n\t\t\t\t\t\t},\n\t\t\t\t\t  ], \n\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [
							{ required: true, min:8, message: 'Please input a password of 8 characters minimum' },
							{ validator: _vm.checkComplexity }
						] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t{ required: true, min:8, message: 'Please input a password of 8 characters minimum' },\n\t\t\t\t\t\t\t{ validator: checkComplexity }\n\t\t\t\t\t\t] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"New Password"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
					'password2',
					{ rules: [
							{ required: true, message: 'Please repeat your password' },
							{ validator: _vm.passwordConfirmationRule }
						] } ]),expression:"[\n\t\t\t\t\t'password2',\n\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t{ required: true, message: 'Please repeat your password' },\n\t\t\t\t\t\t\t{ validator: passwordConfirmationRule }\n\t\t\t\t\t\t] },\n\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Repeat Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'termsandconditions',
						{
							valuePropName: 'checked',
							initialValue: true,
							rules: [
									{ required: true, message: 'Please accept terms and conditions' }
							]
						} ]),expression:"[\n\t\t\t\t\t\t'termsandconditions',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true, message: 'Please accept terms and conditions' }\n\t\t\t\t\t\t\t]\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}]},[_vm._v(" I agree to the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),(_vm.errorState)?_c('a-alert',{attrs:{"message":_vm.message,"type":"warning","show-icon":""}}):_vm._e(),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/signupbg.png')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Register")]),_c('p',{staticClass:"text-lg"},[_vm._v("Use this form to activate your Quikstone Capital Solutions Portal account.")])])])}]

export { render, staticRenderFns }